<template>
  <DocFields
    :modelValue="data"
    @update:modelValue="$emit('update:modelValue', $event)"
  >
    <template #additional-fields>
      <slot name="additional-fields"></slot>
    </template>
    <template #additional-fields-bottom>
      <MultiSelectField
        :modelValue="modelValue.zu"
        @update:modelValue="updateVector($event, v$.zu)"
        :options="vectors"
        @reload="fetchWithDelay"
        :errors="v$.$error ? v$.zu.$errors : []"
        :itemValidationRule="itemValidationRule"
        keyProp="externalId"
      >
        <label class="label label--required">Земельные участки</label>
        <template #add>Добавить</template>
      </MultiSelectField>
      <slot name="additional-fields-bottom"></slot>
    </template>
  </DocFields>
</template>

<script>
import DocVectorZu from "../../../models/documents/docVectorZu";
import MultiSelectField from "../../basic/MultiSelectField";
import DocFields from "./DocFields";
import { useVectorLandPlotOther } from "../../../hooks/vector";
import { toRefs } from "vue";
import { helpers, required } from "@vuelidate/validators";
import useValidator from "./validator";

export default {
  emits: ["update:modelValue", "update:vector"],
  props: {
    modelValue: {
      type: Object,
      required: true,
      default: () => new DocVectorZu(),
    },
  },
  components: { DocFields, MultiSelectField },
  setup(props, { emit }) {
    const { modelValue: data } = toRefs(props);

    const { vectors, fetchWithDelay } = useVectorLandPlotOther();

    function update(fieldName, value, field) {
      const newValue = { ...props.modelValue };
      newValue[fieldName] = value;
      if (field) {
        field.$model = value;
      }
      emit("update:modelValue", newValue);
    }

    let rules = {
      zu: {
        required: helpers.withMessage("Заполните поле", required),
      },
    };

    let itemValidationRule = {
      externalId: helpers.withMessage("Заполните или удалите неиспользуемое поле", (value) => {
        return value !== null && value["externalId"] !== undefined
      })
    }

    const v$ = useValidator(data, rules);

    function updateVector(value, field) {
      update("zu", value, field);
      emit("update:vector", value);
    }
    return { data, update, updateVector, vectors, fetchWithDelay, v$, itemValidationRule };
  },
};
</script>